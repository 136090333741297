/* eslint-disable */
<template>
  <div class="container-fluid">
    <!-- topmost row -->
    <div class="row topBar">
      <!-- Name, load & edit USB -->
      <div class="col">
        <div class="d-inline-flex">
          <img
            :src="require('@/assets/Alterus_Logo.png')"
            alt="Alterus Logo"
            style="height: 3em"
          />
          <h1 class="title blueText ms-2">iALTERUS</h1>
        </div>

        <div class="d-flex flex-row align-items-center mt-2">
          <input
            class="btn btn-primary me-2"
            type="file"
            id="files"
            ref="files"
            style="display: none"
            webkitdirectory
            directory
            @change="
              readUsb();
              readFile(this.organ_index, this.organ_lines);
              readFile(this.config_index, this.config_lines);
              fillBanks();
              usbLoaded = true;
            "
          />
          <button
            class="btn btn-primary me-2 mt-1 nowrap"
            @click="
              $refs.files.value = null;
              $refs.files.click();
            "
          >
            Load USB
          </button>
          <button
            class="btn btn-primary me-2 mt-1 nowrap"
            @click="downloadZip()"
            v-if="usbLoaded"

          >
            Download
          </button>
          <div class="me-2 mt-1 blueText" v-if="usbLoaded">
            {{ config_lines[1] }}
          </div>
          <button
            v-if="usbLoaded"
            class="btn btn-sm btn-primary me-2 mt-1 nowrap"
            data-bs-toggle="modal"
            data-bs-target="#editUSB"
            @click="setChecked();"
          >
            Edit USB
          </button>
        </div>
      </div>
      <!-- Select & search Banks, Pagination -->
      <div class="col" v-if="usbLoaded">
        <!--  Select & Search -->
        <div class="row mt-2">
          <div class="col-4">
            <select
              class="form-select ms-2"
              name="pickBank"
              v-model="selectedBank"
              @change="(selectedCombinationIndexes = []), (currentPage = 1)"
            >
              <option :value="0" disabled hidden>Select Bank</option>
              <option
                v-for="(content, index) in banks"
                :key="index"
                v-bind:value="index"
              >
                {{ content.name }}
              </option>
            </select>
          </div>
          <div class="col-5">
            <div class="d-flex flex-row align-items-center">
              <button
                v-if="selectedBank"
                class="btn btn-sm btn-primary mt-1"
                data-bs-toggle="modal"
                data-bs-target="#renameBank"
              >
                Rename
              </button>
              <button
                class="btn btn-sm btn-primary mt-1 ms-1"
                data-bs-toggle="modal"
                data-bs-target="#newBank"
              >
                New
              </button>
              <span class="ms-1 nowrap">Page size:</span>
              <a
                v-if="pageSize"
                class="ms-2"
                href="#"
                @click="setCurrentPageSize(10)"
              >
                {{ 10 }}</a
              >
              <a
                v-if="pageSize"
                class="ms-2"
                href="#"
                @click="setCurrentPageSize(25)"
              >
                {{ 25 }}</a
              >
              <a
                v-if="pageSize"
                class="me-2 ms-2"
                href="#"
                @click="setCurrentPageSize(50)"
              >
                {{ 50 }}</a
              >
            </div>
          </div>
          <div class="col-3">
            <div class="input-group rounded">
              <input
                type="search"
                class="form-control rounded"
                placeholder="Search"
                aria-label="Search"
                aria-describedby="search-addon"
              />
            </div>
          </div>
        </div>
        <!-- Pagination -->
        <div
          class="d-flex flex-row align-items-center mt-3"
          v-if="selectedBank"
        >
          <div class="d-inline-flex">
            <button class="btn btn-primary me-2 ms-2" @click="prevPage">
              Previous
            </button>
          </div>
          <div class="d-inline-flex">
            <button class="btn btn-primary me-2 ms-2" @click="nextPage">
              Next
            </button>
          </div>
          <div class="d-inline-flex justify-content-center" style="width: 25em">
            <a
              v-if="currentPage > 2"
              class="me-2 ms-2"
              href="#"
              @click="setCurrentPage(1)"
            >
              {{ "1-" + pageSize }}</a
            >
            <span v-if="currentPage > 2">...</span>

            <a
              v-if="currentPage - 1 > 0"
              class="me-2 ms-2"
              href="#"
              @click="setCurrentPage(currentPage - 1)"
            >
              {{
                pageSize * (currentPage - 1) -
                pageSize + 1 +
                "-" +
                pageSize * (currentPage - 1)
              }}</a
            >
            {{
              pageSize * currentPage - pageSize + 1 +
              "-" +
              (currentPage == maxPage ? (pageSize * currentPage  - 1) : (pageSize * currentPage))
            }}
            <a
              v-if="(currentPage + 1) * pageSize < maxCombinationsPerBank"
              class="me-2 ms-2"
              href="#"
              @click="setCurrentPage(currentPage + 1)"
            >
              {{
                pageSize * (currentPage + 1) -
                pageSize + 1 +
                "-" +
                pageSize * (currentPage + 1)
              }}</a
            >

            <span
              v-if="(currentPage + 1) * pageSize < maxCombinationsPerBank"
              >...</span
            >
            <a
              v-if="currentPage * pageSize < maxCombinationsPerBank"
              class="me-2 ms-2"
              href="#"
              @click="
                setCurrentPage(maxPage)
              "
            >
              {{
                (pageSize * maxPage - pageSize + 1) +
                "-" +
                (pageSize * maxPage - 1)
              }}</a
            >
          </div>

          <div class="d-inline-flex">
            <div class="input-group rounded" style="width: 6em">
              <input
                type="number"
                class="form-control rounded"
                min="1"
                :max="maxCombinationsPerBank / pageSize + 1"
                :placeholder="currentPageInputLine"
                v-model="currentPageInputLine"
                v-on:change="setCurrentPageByLine(currentPageInputLine)"
              />
            </div>
          </div>
        </div>
      </div>
      <!-- Copy & paste buttons-->
      <div class="col">
        <div
          class="d-flex flex-row align-items-center mt-3"
          v-if="selectedBank"
        >
          <button
            class="btn btn-primary m-1"
            @click="copyCombinations()"
            :disabled="selectedCombinationIndexes.length == 0"
          >
            Copy combinations
          </button>
          <button
            class="btn btn-primary m-1"
            @click="clearCombinations()"
            :disabled="selectedCombinationIndexes.length == 0"
          >
            Clear combinations
          </button>
          <button
            class="btn btn-primary m-1"
            @click="deleteCombinations()"
            :disabled="selectedCombinationIndexes.length == 0"
          >
            Delete combinations
          </button>
          <button
            class="btn btn-primary m-1"
            @click="pasteCombinations()"
            :disabled="pasteDisabled"
          >
            Paste combinations
          </button>
          <input
            type="number"
            class="form-control rounded"
            style="width: 80px"
            v-model="insertStartIndex"
            min="1"
            :max="maxCombinationsPerBank"
          />
        </div>
      </div>
    </div>

    <!-- checkbox matrix -->
    <div class="row" v-if="selectedBank != 0">
      <div class="table-holder mt-2">
        <div class="tableFixHead table-responsive border rounded" ref="table">
          <table class="table table-striped table-bordered mb-0">
            <!-- Table Header -->
            <thead>
              <tr class="table-active">
                <th
                  class="sticky-col first-col"
                  style="z-index: 5; min-width: 105px"
                >
                  Comb. Nr.
                </th>
                <th
                  class="vertical-text"
                  style="max-width: 30px"
                  scope="col"
                  v-for="line in organ_lines.slice(1)"
                  :key="line"
                >
                  <div class="" style="white-space: nowrap">{{ line }}</div>
                </th>
              </tr>
            </thead>
            <!-- Table Body -->
            <tbody v-if="banks[selectedBank] !== undefined">
              <tr
                scope="col"
                v-for="(combination, idx) in paginatedLines"
                :key="combination"
                :class="[combination.isEmpty ? 'empty-combo' : '']"
              >
                <td class="sticky-col first-col">
                  <input
                    type="checkbox"
                    v-model="selectedCombinationIndexes"
                    :value="idx"
                    :class="[combination.isSubcombo ? 'subCombo' : '']"
                    @change="updateWarningList(combination, $event.target.checked)"
                  />
                  {{ combination.index }}
                  <button
                    class="btn btn-primary subcomboBtn"
                    v-if="combination.canAddSubcombo && !combination.isEmpty"
                    @click="addSubcombo(combination.index)"
                  >
                    +
                  </button>
                </td>
                <td v-for="(pipe, index) in organ_lines.slice(1)" :key="pipe">
                  <input
                    type="checkbox"
                    v-model="combination.flags[index]"
                    @change="
                      banks[selectedBank].changed = true;
                      banksChanged = true;
                      checkCombinationEmpty(combination);
                    "
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal USB Opptions -->
  <div
    class="modal fade"
    id="editUSB"
    tabindex="-1"
    aria-labelledby="editUSBlabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="editUSBlabel">USB Options:</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="col">
            <div v-if="config_lines[2]" class="mt-3 mb-3 fw-bold">
              <div class="row mb-4">
                <div class="col">
                  {{ config_lines[0] }}
                </div>
              </div>
              <div class="row mb-4">
                <div class="col d-inline-flex flex-nowrap">
                  <div class="align-self-center me-2">USB name:</div>
                  <input
                    type="text"
                    class="form-control rounded"
                    style="max-width: 250px"
                    placeholder="USB Name"
                    v-model.lazy="config_lines[1]"
                  />
                </div>
              </div>
              <div class="row mb-4">
                <div class="col d-inline-flex flex-nowrap">
                  <div class="align-self-center me-2">Default bank:</div>
                  <input
                    type="number"
                    class="form-control rounded"
                    style="max-width: 80px"
                    placeholder="Default bank"
                    v-model="bootStartVals[1]"
                    label="Test"
                  />
                  <div class="align-self-center ms-2 me-2">
                    Default combination:
                  </div>
                  <input
                    type="text"
                    class="form-control rounded"
                    style="max-width: 80px"
                    placeholder="Default combination"
                    v-model="bootStartVals[2]"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col">
                  Option
                  {{
                    config_lines[3].substring(0, config_lines[3].indexOf(","))
                  }}
                  -
                  <input
                    type="checkbox"
                    name="savePlus"
                    v-model="checked"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Close
          </button>
          <button type="button" class="btn btn-primary" @click="saveConfig()">
            Download config.dat
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal Rename bank -->
  <div
    class="modal fade"
    id="renameBank"
    tabindex="-1"
    aria-labelledby="renameBankLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="renameBankLabel">Rename bank:</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="col d-inline-flex flex-nowrap">
            <input
              v-if="selectedBank"
              type="text"
              class="form-control rounded"
              style="max-width: 250px"
              placeholder="Bank name"
              v-model.lazy="banks[selectedBank].name"
              @change="
                banks[selectedBank].changed = true;
                banksChanged = true;
              "
            />
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" data-bs-dismiss="modal">
            OK
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal New bank -->
  <div
    class="modal fade"
    id="newBank"
    tabindex="-1"
    aria-labelledby="newBankLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="newBankLabel">Create new bank:</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="col d-inline-flex flex-nowrap">
            <div class="align-self-center me-2">Bank name:</div>
            <input
              type="text"
              class="form-control rounded"
              style="max-width: 250px"
              placeholder="Bank name"
              v-model.lazy="newBankName"
            />
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-primary"
            data-bs-dismiss="modal"
            @click="newBank(newBankName)"
          >
            OK
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import JSZip from "jszip";

export default {
  name: "App",
  components: {},
  data: () => ({
    organ_lines: [],
    organ_index: null,
    config_lines: [],
    config_index: null,
    banks: {},
    selectedBank: 0,
    usbLoaded: false,
    banksChanged: false,
    selectedCombinationIndexes: [],
    combinationsClipboard: [],
    pageSize: 50,
    currentPage: 1,
    insertStartIndex: null,
    currentPageInput: 1,
    currentPageInputLine: 1,
    newBankName: "",
    maxCombinationsPerBank: 999,
    checked: null,
    selectedCombinationsWithSubcombinations: [],
    emptyCombo: {
      index: "0",
      isEmpty: true,
      isSubcombo: false,
      hasSubcombinations: false,
      flags: Array(128).fill(false),
      canAddSubcombo: false,
    }
  }),
  methods: {
    readUsb() {
      // reset defaults on USB reload
      this.organ_lines = [];
      this.organ_index = null;
      this.config_lines = [];
      this.config_index = null;
      this.banks = {};
      this.selectedBank = 0;
      this.banksChanged = false;
      this.combinationsClipboard = [];
      this.resetSelection();

      let files = document.getElementById("files").files;
      let file = null;
      for (var i = 0; i < files.length; i++) {
        file = files[i].name;

        if (file.search(/organ.dat/i) != -1) {
          this.organ_index = i;
          continue;
        }

        if (file.search(/config.dat/i) != -1) {
          this.config_index = i;
          continue;
        }

        // TODO_BB: need to add filename check for banks
        //  inconsistency between sample files and specs
        this.banks[file] = { fileIndex: i, changed: false };
      }
      if (this.organ_index === null) {
        alert("missing organ.dat");
        return;
      }
      if (!this.config_index === null) {
        alert("missing config.dat");
        return;
      }
      if (Object.keys(this.banks).length == 0) {
        alert("missing banks");
        return;
      }
    },
    readFile(file_index, output_lines) {
      const reader = new FileReader();
      reader.onload = (res) => {
        var text = res.target.result;
        this.content = text;
        var lines = text.split(/[\r\n]+/g); // tolerate both Windows and Unix linebreaks
        for (var i = 0; i < lines.length; i++) {
          output_lines.push(lines[i]);
        }
        if (output_lines[output_lines.length - 1] == "") {
          output_lines.splice(output_lines.length - 1, 1);
        }
      };
      reader.onerror = (err) => console.log(err);
      reader.readAsText(this.$refs.files.files[file_index]);
    },
    setChecked() {
      this.checked = this.config_lines[3].substring(this.config_lines[3].length - 1) == 1;
    },
    readBank(fileName) {
      const reader = new FileReader();
      reader.onload = (res) => {
        var text = res.target.result;
        this.content = text;
        var lines = text.split(/[\r\n]+/g); // tolerate both Windows and Unix linebreaks
        var combos = [];
        this.banks[fileName]["name"] = lines.shift();
        lines.sort();
        for (var i = 1; i < lines.length; i++) {
          var separatorIndex = lines[i].indexOf(",");
          var isSubcombo = separatorIndex == 4;
          var combo = {};
          combo["index"] = lines[i].substring(0, separatorIndex);
          combo["flags"] = Array(128).fill(false);
          combo["canAddSubcombo"] = false;
          for (var j = separatorIndex + 1; j < lines[i].length; j++) {
            combo["flags"][j - separatorIndex - 1] = lines[i].substring(j, j + 1) == 1;
          }

          let emptyLinesInsertCount = 0;
          let index = parseInt(combo["index"]);
          if (index > this.maxCombinationsPerBank) {
            // Ignore lines in files that have index greater than 999
            break;
          }
          if (i == 1 && index != 1) {
            emptyLinesInsertCount = index - 1;
          } else if (index - parseInt(lines[i - 1].substring(0, separatorIndex)) > 1) {
            emptyLinesInsertCount =
              index - parseInt(lines[i - 1].substring(0, separatorIndex)) - 1;
          }
          for (var k = 0; k < emptyLinesInsertCount; k++) {
            combos.push(JSON.parse(JSON.stringify(this.emptyCombo)));
          }

          combo["isEmpty"] = combo["flags"].indexOf(true) == -1;
          combo["isSubcombo"] = isSubcombo;
          if (isSubcombo && (combos.length > 0 && !combos[combos.length - 1].isSubcombo)) {4
            combos[combos.length - 1].hasSubcombinations = true;
          }
          combos.push(combo);
        }
        for (let i = parseInt(combos[combos.length - 1].index); i < this.maxCombinationsPerBank; i++) {
          combos.push(JSON.parse(JSON.stringify(this.emptyCombo)));
        }
        combos[0].index = "001"; // First combo should always have an index of '001' so this guarantees the [i-1] expression below does not evaluate to -1
        for (let i in combos) {
          if (combos[i].index == "0") {
            combos[i].index = String(parseInt(combos[i-1].index) + 1).padStart(3, "0");
          }
        }
        this.reevaluateSubcomboAdition(combos);
        this.banks[fileName]["combinations"] = combos;
      };
      reader.onerror = (err) => console.log(err);
      reader.readAsText(
        this.$refs.files.files[this.banks[fileName]["fileIndex"]]
      );
    },
    fillBanks() {
      for (const name in this.banks) {
        this.readBank(name);
      }
    },
    downloadZip() {
      var zip = new JSZip();

      //save organ.dat
      let organDat = JSON.parse(JSON.stringify(this.organ_lines));
      let results = "";
        for (var line in organDat) {
          results += organDat[line] + "\n";
        }
      zip.file("ALTERUS/ORGAN.DAT", results);


      // save config
      var alterusSpecs = this.config_lines[0];
      var usbName = this.config_lines[1];
      var bootStart = this.bootStartVals.join(",");
      var savePlusVal = false;
      var savePlus = "";
      if (this.checked != null) {
        savePlusVal = this.checked;
      } else {
        savePlusVal =
          this.config_lines[3].substring(this.config_lines[3].length - 1) == 1;
      }
      if (savePlusVal == false) {
        savePlus = "SAVE+,0";
      } else {
        savePlus = "SAVE+,1";
      }

      let fileName = "CONFIG.DAT";
      let content =
        alterusSpecs + "\n" + usbName + "\n" + bootStart + "\n" + savePlus;

      zip.file("ALTERUS/"+fileName, content);

      // save banks
      for (var bank in this.banks) {
        let content = this.banks[bank].name;
        // assumption here is that the combinations are in a correct order
        for (var i in this.banks[bank].combinations) {
          if (!this.banks[bank].combinations[i].isEmpty) {
            content += `\r\n${
              this.banks[bank].combinations[i].index
            },${this.banks[bank].combinations[i].flags
              .map((b) => (b ? 1 : 0))
              .join("")}`;
          }
        }
        zip.file("ALTERUS/"+bank, content);
      }

      zip.generateAsync({ type: "base64" }).then(
        function (base64) {
          window.location = "data:application/zip;base64," + base64;
        }
      );
    },
    copyCombinations() {
      this.combinationsClipboard = [];
      this.selectedCombinationIndexes.sort((a, b) => { return a.parseInt < b.parseInt; });
      for (var i in this.selectedCombinationIndexes) {
        this.combinationsClipboard.push(
          JSON.parse(
            JSON.stringify(
              this.paginatedLines[
                this.selectedCombinationIndexes[i]
              ]
            )
          )
        );
      }
      this.resetSelection();
      this.insertStartIndex = null;
    },
    pasteCombinations() {
      let start;
      if (!this.insertStartIndex) {
        if (this.paginatedLines[this.selectedCombinationIndexes[0]].isSubcombo) {
          alert("Cannot paste to a subcombination");
          return;
        }
        start = this.banks[this.selectedBank].combinations.findIndex((x) => x.index == this.paginatedLines[this.selectedCombinationIndexes[0]].index);
      }
      else {
        start = this.banks[this.selectedBank].combinations.findIndex((x) => x.index == String(this.insertStartIndex).padStart(3, "0"));
      }

      let emptyCount = 0;
      for (
        let i = start;
        i < this.banks[this.selectedBank].combinations.length;
        i++
      ) {
        if (this.banks[this.selectedBank].combinations[i].isEmpty) {
          emptyCount++;
          this.banks[this.selectedBank].combinations.splice(i, 1);
          i--;
        } else if (emptyCount) {
          break;
        }
        if (emptyCount == this.combinationsClipboard.length) {
          break;
        }
      }
      for (let i = this.combinationsClipboard.length - 1; i >= 0; i--) {
        this.combinationsClipboard[i].isSubcombo = false;
        this.banks[this.selectedBank].combinations.splice(
          start,
          0,
          JSON.parse(JSON.stringify(this.combinationsClipboard[i])) //tried Object.assign({}, x) but references remain, unfortunately structuredClone is not yet supported
        );
      }
      this.banks[this.selectedBank].combinations.splice(this.maxCombinationsPerBank);
      this.reindex();
      this.reevaluateSubcomboAdition(this.banks[this.selectedBank].combinations);
      this.insertStartIndex = null;
      this.resetSelection();
    },
    deleteCombinations() {
      if (this.selectedCombinationsWithSubcombinations.length != 0 &&
          !confirm("This action will also delete subcombinations of " + this.selectedCombinationsWithSubcombinations.join(", "))) {
        return;
      }

      this.selectedCombinationIndexes.sort((a, b) => { return a.parseInt < b.parseInt; }).reverse();
      let combos = this.banks[this.selectedBank].combinations;
      for (var i in this.selectedCombinationIndexes) {
        var globalIndex = combos.findIndex((x) => x.index == this.paginatedLines[this.selectedCombinationIndexes[i]].index)
        if (combos[globalIndex].isSubcombo) {
            this.banks[this.selectedBank].combinations.splice(
              globalIndex,
              1
            );
        }
        else {
          combos = combos.filter((row, id) => { return parseInt(combos[id].index) != parseInt(combos[globalIndex].index); });
        }
      }
      this.banks[this.selectedBank].combinations = combos;
      this.reindex();
      this.reevaluateSubcomboAdition(this.banks[this.selectedBank].combinations);
      this.resetSelection();
    },
    clearCombinations() {
      if (this.selectedCombinationsWithSubcombinations.length != 0 &&
          !confirm("This action will also delete subcombinations of " + this.selectedCombinationsWithSubcombinations.join(", "))) {
        return;
      }

      this.selectedCombinationIndexes.sort((a, b) => { return a.parseInt < b.parseInt; }).reverse();
      let combos = this.banks[this.selectedBank].combinations;
      for (var i in this.selectedCombinationIndexes) {
        var globalIndex = combos.findIndex((x) => x.index == this.paginatedLines[this.selectedCombinationIndexes[i]].index)
        var cmb = combos[globalIndex];
        cmb.flags.fill(false);
        cmb.isEmpty = true;
        if (!cmb.isSubcombo) {
          combos = combos.filter((row, id) => {return !((parseInt(combos[id].index) == cmb.index) && combos[id].index.length == 4); })
        }
      }
      this.banks[this.selectedBank].combinations = combos;
      this.reevaluateSubcomboAdition(this.banks[this.selectedBank].combinations);
      this.resetSelection();
    },
    reindex() {
      var lastComboIndex = "000";
      var lastCharCode = 65;
      let combos = this.banks[this.selectedBank].combinations;
      for (let i in combos) {
        if (combos[i].isSubcombo) {
          combos[i].index = lastComboIndex + String.fromCharCode(lastCharCode);
          lastCharCode++;
        }
        else {
          combos[i].index = lastComboIndex = String(
            parseInt(lastComboIndex) + 1
          ).padStart(3, "0");
          lastCharCode = 65;
        }
      }
    },
    wheelHorizontal(e) {
      if (e.deltaY < 0) {
        this.$refs.table.scrollLeft = this.$refs.table.scrollLeft - 50;
      } else {
        this.$refs.table.scrollLeft = this.$refs.table.scrollLeft + 50;
      }
    },
    nextPage() {
      if (
        this.currentPage * this.pageSize <
        this.banks[this.selectedBank].combinations.length
      ) {
        this.currentPage++;
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    setCurrentPage(curP) {
      if (
        (curP - 1) * this.pageSize <
          this.banks[this.selectedBank].combinations.length &&
        curP > 0
      ) {
        this.currentPage = this.currentPageInput = curP;
      }
    },
    setCurrentPageByLine(curPL) {
      var curP = 1;
      curP = Math.ceil(curPL / this.pageSize);

      if (
        (curP - 1) * this.pageSize <
          this.banks[this.selectedBank].combinations.length &&
        curP > 0
      ) {
        this.currentPage = this.currentPageInput = curP;
      }
    },
    setCurrentPageSize(pageS) {
      this.pageSize = pageS;
      this.currentPage = this.currentPageInput = this.currentPageInputLine = 1;
    },
    checkCombinationEmpty(combination) {
      combination.isEmpty = combination.flags.indexOf(true) == -1;
      this.selectedCombinationIndexes = this.selectedCombinationIndexes.filter(
        (x) => x != combination.index - 1
      );
    },
    saveConfig() {
      var alterusSpecs = this.config_lines[0];
      var usbName = this.config_lines[1];
      var bootStart = this.bootStartVals.join(",");
      var savePlusVal = false;
      var savePlus = "";
      if (this.checked != null) {
        savePlusVal = this.checked;
      } else {
        savePlusVal =
          this.config_lines[3].substring(this.config_lines[3].length - 1) == 1;
      }
      if (savePlusVal == false) {
        savePlus = "SAVE+,0";
      } else {
        savePlus = "SAVE+,1";
      }

      let fileName = "CONFIG.DAT";
      let content =
        alterusSpecs + "\n" + usbName + "\n" + bootStart + "\n" + savePlus;

      let bl = new Blob([content], {
        type: "UTF-8",
      });
      let a = document.createElement("a");
      a.href = URL.createObjectURL(bl);
      a.download = fileName;
      a.hidden = true;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
    newBank(bankName) {
      var bankNums = Object.keys(this.banks).map(x => parseInt(x.substring(4, 6)));
      var fileName = "";
      for (let i = 1; i < 100; i++) {
        if (!bankNums.includes(i)) {
          fileName = "BANK" + String(i).padStart(2, "0") + ".TXT";
          break;
        }
      }
      if (fileName == "") {
        alert("Cannot add new bank: limit reached");
        return;
      }

      var combos = [];
      for (let i = combos.length; i < this.maxCombinationsPerBank; i++) {
        combos.push(JSON.parse(JSON.stringify(this.emptyCombo)));
      }
      for (let i in combos) {
        combos[i].index = String(parseInt(i) + 1).padStart(3, "0");
      }
      this.reevaluateSubcomboAdition(combos);

      this.banks[fileName] = [];
      this.banks[fileName]["combinations"] = combos;
      this.banks[fileName]["name"] = bankName;
      this.banks[fileName]["changed"] = true;

      this.banksChanged = true;
      this.selectedBank = fileName;
      this.newBankName = "New bank";
    },
    updateWarningList(combo, add) {
      if (!combo.hasSubcombinations) {
        return;
      }

      if (add) {
        this.selectedCombinationsWithSubcombinations.push(combo.index);
      }
      else {
        var i = this.selectedCombinationsWithSubcombinations.indexOf(combo.index);
        this.selectedCombinationsWithSubcombinations.splice(i,1);
      }
    },
    resetSelection() {
      this.selectedCombinationIndexes = [];
      this.selectedCombinationsWithSubcombinations = [];
    },
    reevaluateSubcomboAdition(combinations) {
      var len = combinations.length;
      for (let i in combinations) {
        let j = parseInt(i)+1;
        combinations[i].canAddSubcombo = combinations[i].index.indexOf("E") == -1 && (j < len ? parseInt(combinations[i].index) != parseInt(combinations[j].index) : true);
      }
    },
    addSubcombo(index) {
      var c = JSON.parse(JSON.stringify(this.emptyCombo));
      c.isSubcombo = true;
      this.banks[this.selectedBank].combinations.splice(
        this.banks[this.selectedBank].combinations.findIndex((x) => x.index == index) + 1,
        0,
        c
      );
      this.reindex();
      this.reevaluateSubcomboAdition(this.banks[this.selectedBank].combinations);
    }
  },
  computed: {
    paginatedLines() {
      let start = (this.currentPage - 1) * this.pageSize + 1;
      let end = this.currentPage * this.pageSize + 1;
      let combos = this.banks[this.selectedBank].combinations;
      return combos.filter((row, i) => { return (parseInt(combos[i].index) >= start && parseInt(combos[i].index) < end); });
    },
    bootStartVals() {
      if (this.config_lines) {
        var vals = this.config_lines[2].split(",");
        return vals;
      } else {
        return ["BOOT", 1, 1];
      }
    },
    pasteDisabled() {
      return (
        this.combinationsClipboard.length == 0
        || (this.insertStartIndex && this.selectedCombinationIndexes.length != 0)
        || (!this.insertStartIndex && this.selectedCombinationIndexes.length != 1)
      );
    },
    maxPage() {
      return Math.ceil(this.maxCombinationsPerBank / this.pageSize);
    },
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  color: #2c3e50;
  margin-top: 10px;
}

.tableFixHead {
  overflow: auto;
  height: 85vmin;
  min-height: 200px;
}
.tableFixHead thead th {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1;
}

.tableFixHead th {
  font-size: 12px;
}

/* table  { border-collapse: collapse; width: 100%; } */
th,
td {
  padding: 8px 16px;
}
th {
  background: rgb(255, 255, 255) !important;
}

.sticky-col {
  position: -webkit-sticky;
  position: sticky;
  background-color: white !important;
}

.first-col {
  width: 100px;
  left: 0px;
  box-shadow: inset black;
}

.title {
  font-size: 3em;
}
.blueText {
  color: rgb(54, 102, 192);
  font-weight: bold;
}

.rotate {
  transform: rotate(-90deg);
  /* Safari */
  -webkit-transform: rotate(-90deg);
  /* Firefox */
  -moz-transform: rotate(-90deg);
  /* IE */
  -ms-transform: rotate(-90deg);
  /* Opera */
  -o-transform: rotate(-90deg);
  /* Internet Explorer */
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
}

.vertical-text {
  -ms-writing-mode: vertical-rl !important;
  -webkit-writing-mode: vertical-rl !important;
  -moz-writing-mode: vertical-rl !important;
  writing-mode: vertical-rl !important;
}

.nowrap {
  white-space: nowrap;
}

.empty-combo {
  background-color: rgba(204, 204, 207, 0.801) !important;
  --bs-table-striped-bg: none !important;
}

.subCombo {
  margin-left: 10px;
}

.subcomboBtn {
  float: right;
  padding: 0px 2px !important;
}
</style>
